import dayjs from "dayjs";
const useAppDownload = () => {
	const route = useRoute();
	const { isAndroid, isIos } = useDevice();
	const { data: appInit } = useAppInitData();
	const { open } = useFunrizeModals();
	const { game } = route.query;

	const {
		public: { appLinks }
	} = useRuntimeConfig();

	const downloadAppVerificationTimerCookie = useStatefulCookie<string | null>("getAppHeaderBannerShowed", {
		expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
		path: "/"
	});

	const showDownloadAppPopupCookie = useStatefulCookie<string | null>("showDownloadAppPopup", {
		expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
		path: "/"
	});

	const showDownloadAppPopupOnceAWeekCookie = useStatefulCookie<string | null>("showDownloadAppPopupOnceAWeek", {
		expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
		path: "/"
	});

	const platform = ref<"android" | "ios">(isAndroid ? "android" : "ios");
	const configAppId = {
		android: appInit.value?.androidAppId,
		ios: appInit.value?.appleAppId
	};
	const androidLink = `${appLinks.android}${configAppId.android}`;
	const iosLink = `${appLinks.ios}${configAppId.ios}`;

	const onClickDownloadApp = (system = platform.value) => {
		window.location.href = `${appLinks[system]}${configAppId[system]}`;
	};

	const showAllLinks = computed(() => !isAndroid && !isIos && (iosLink || androidLink));
	const showLockedItem = computed(
		() => (platform.value && `${appLinks[platform.value]}${configAppId[platform.value]}`) || showAllLinks.value
	);

	const onShowAppPopup = ({ isIgnoreCookie = false }: { isIgnoreCookie?: boolean } = {}) => {
		const { showAppFeaturesForGuest, isGuest, showDownloadAppPopup, depositsCount } = appInit?.value || {};
		if (depositsCount === 0) {
			return;
		}

		const showStatus = showDownloadAppPopupCookie.value;
		const initialTime = dayjs(Number(showStatus));
		const onceAWeekMode = showDownloadAppPopupOnceAWeekCookie.value;
		const comparison = dayjs().diff(initialTime, "day") > 1;
		const delayEnds = showStatus && showStatus.toString() !== "onReload" && comparison;

		const show = !showStatus || delayEnds;

		if (
			(showAppFeaturesForGuest || !isGuest) &&
			showDownloadAppPopup &&
			showLockedItem.value &&
			(show || isIgnoreCookie) &&
			!game &&
			!route.path.includes("cash")
		) {
			if (delayEnds || (!showStatus && onceAWeekMode)) {
				showDownloadAppPopupCookie.value = "onReload";
			}
			open("LazyOModalGetAppMain");

			if ((showAppFeaturesForGuest || !isGuest) && !onceAWeekMode) {
				showDownloadAppPopupCookie.value = Date.now().toString();
				showDownloadAppPopupOnceAWeekCookie.value = "1";
			}
		}
	};

	return {
		configAppId,
		appLinks,
		platform,
		downloadAppVerificationTimerCookie,
		onShowAppPopup,
		onClickDownloadApp
	};
};
export default useAppDownload;
